import React from 'react'
import { art } from 'lib/products'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import ProductGrid from 'components/product-grid'
import Title from 'components/title'
import LeadForm from 'components/forms/lead-form'

const ShopPage = () => (
  <Layout path="/shop">
    <Wrap>
      <Title is="h1">Original Art & Prints</Title>
      <ProductGrid columns={4} products={art} />
    </Wrap>
    <LeadForm />
  </Layout>
)

export default ShopPage
